function Undefined() {

    return (
        <div>
            <h1 className="home_links">
                Oops! Page not found.
            </h1>
            <img
                alt="home banner"
                className="undefined_icon" 
                src="images/WildDogsIcon.webp"
                loading="lazy" 
                /> 
        </div>
    );
}

export default Undefined;
