import { useEffect, useState } from "react";
import HostComponent from "./Components/HostComponent";
import Hosts from "../Data/hosts.json";

function About() {
    const keys = Object.keys(Hosts) as Array<keyof typeof Hosts>;
    const [resourcesLoading, setResourcesLoading] = useState<boolean>(true);

    // Resource image loading. Makes sure page content loads at same time.
    useEffect(() => {
        const handleLoad = () => setResourcesLoading(false);
        
        if (document.readyState === 'complete') {
          setResourcesLoading(false);
        } else {
          window.addEventListener('load', handleLoad);
        }
        
        return () => window.removeEventListener('load', handleLoad);
      }, []);


    // Make sure UI dom resources are loaded.
    if (resourcesLoading) {
        return (<div></div>);
    }

    return (
        <div className="page_content">

            <h2>
                ORIGIN STORY 
            </h2>
            <p className="home_text">
                Wild Dogs is a Magic: The Gathering podcast founded in 2023 by co-hosts Leland and Luke. What began as casual discussions between friends quickly transformed into a dedicated show exploring everything from deck techs and shifting metas to deep lore dives and the ever-evolving world of Magic.
            </p>
            <p className="home_text">
                With their combined experience and enthusiasm, Leland and Luke deliver insightful analysis, entertaining discussions, and a genuine love for the game that keeps listeners coming back. Whether you're a competitive grinder, a casual Commander fan, or just someone who loves talking Magic, Wild Dogs has something for you.
            </p>


            <h2 className="about_section">
                THE HOSTS 
            </h2>

            <div>
                <ul>
                {
                    keys.map((key, x) => (
                        <HostComponent key={x} inHost={key}/> 
                    ))
                }
                </ul>
            </div>
        
        </div>
    );
}

export default About;
