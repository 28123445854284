import Hosts from "../../Data/hosts.json";
import MoxfieldIcon from "../Icons/MoxfieldIcon";
import TwitterIcon from "../Icons/TwitterIcon";

function HostComponent({ inHost }: {inHost: keyof typeof Hosts}) {

    function getSocialIcons() {
        const keys = Object.keys(Hosts[inHost].socials);
        return keys.map((key) => {
            if (key === "twitter") {
                return (
                    <TwitterIcon 
                    key={key}
                    url={Hosts[inHost].socials[key as keyof typeof Hosts.Luke.socials]} 
                    />
                );
            }
            if (key === "moxfield") {
                return (
                    <MoxfieldIcon 
                    key={key}
                    url={Hosts[inHost].socials[key as keyof typeof Hosts.Luke.socials]} 
                    />
                );
            }
            return null;
        });
    }

    function getHostHeadshot() {
        if (inHost === "Leland") {
            return (
            <img
                alt="Leland Headshot"
                className="hosts_image" 
                src="images/LelandHeadshot.webp"
                loading="lazy" 
                /> 
            ); 
        }

        return (
            <img
            alt="Luke Headshot"
            className="hosts_image" 
            src="images/LukeHeadshot.webp"
            loading="lazy" 
            /> 
        ); 
    }

    return (
        <div className="hosts_container">
            <div>

                {getHostHeadshot()} 

                <h1>{inHost}</h1>
                <p>{Hosts[inHost].title}</p> 

            </div>
           
            <div className="hosts_description_container">
                <p className="hosts_description">{Hosts[inHost].description}</p>
                {getSocialIcons()}
            </div>


        </div>
    );
}

export default HostComponent;
